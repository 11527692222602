import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './HeaderMenu.css';
import { Avatar, Button, Col, Layout, Row, Typography, Tooltip, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, CopyOutlined } from '@ant-design/icons';
import { useStoreService, useUserService } from '../../services';

const { Header } = Layout;
const { Text } = Typography;

const HeaderMenu = () => {
    const navigate = useNavigate();
    const userService = useUserService();
    const [selectedFile, setSelectedFile] = useState(null);
    const [loadingPicture, setLoadingPicture] = useState(false);
    const storeService = useStoreService();
    const [loading, setLoading] = useState(false);
    const [storeDetails, setStoreDetails] = useState(null);
    let { imageKey } = useSelector((state) => state.user);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getStoreDetails();
            setLoading(false);
        })();
    }, []);

    const getStoreDetails = async () => {
        try {
            const store_details = storeService.getCurrentStoreDetails();
            const response = await storeService.getStoreDetails(store_details?.store_url);

            if (response && response?.data) {
                setStoreDetails(response?.data);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        async function fetchData() {
            await loadImage();
        }
        fetchData();
    }, [imageKey]);

    const loadImage = async () => {
        setLoadingPicture(true);
        // Placeholder for loading the profile image logic
        setLoadingPicture(false);
    }

    const handleProfileClick = () => {
        navigate('/profile');
    };

    const handleLogoClick = () => {
        navigate('/home');
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(storeDetails?.store_url);
        message.success('Store name copied to clipboard!');
    };

    return (
        <Header
            className="custom-header"
            style={{
                position: 'fixed',
                zIndex: 100,
                width: '100%',
                backgroundColor: '#fff',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
            }}
        >
            <Row justify="space-between" align="middle">
                <Col>
                    <div className="logo" onClick={handleLogoClick}>
                        <div className='logo-container'>
                            <strong style={{ fontSize: 28, color: "#4A43A7" }}>NICK</strong>
                        </div>
                    </div>
                </Col>
                <Col>
                    <Row align="middle" style={{ alignItems: 'center' }}>
                        {storeDetails && (
                            <Col>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: '1px solid #D3D3D3',  // Light grey border
                                        borderRadius: '4px',
                                        padding: '2px',
                                        fontSize: '10px',
                                        backgroundColor: '#F5F5F5'  // Light background color for the combined element
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: '#6C63FF',  // Lighter color for better visibility
                                            marginLeft: '8px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={copyToClipboard}
                                    >
                                        {storeDetails.store_url}
                                    </Text>
                                    <Button
                                        icon={<CopyOutlined />}
                                        size="small"
                                        className={'primary_btn'}
                                        style={{ marginLeft: '8px', color: '#6C63FF', minWidth: '40px' }}  // Match icon color with text
                                        onClick={copyToClipboard}
                                    />
                                </div>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </Header>
    );
};

export default HeaderMenu;
