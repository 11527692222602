import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export { PrivateRoute };

function PrivateRoute({ children }) {
    const location = useLocation();
    const { token } = useSelector((state) => state.auth);

    // Fetch user from localStorage
    const storedUser = sessionStorage.getItem('user');

    // Convert string to object, since localStorage can only store strings.
    const user = storedUser ? JSON.parse(storedUser) : null;

    console.log(token)

    // Check if a user token exists in the Redux store or if a user exists in localStorage
    const isAuthenticated = token || user;

    //Setting redirect URL after user logged in (If user not logged in yet)
    // if (!isAuthenticated) {
    //     localStorage.setItem('loginRedirectURL', window.location.href);
    // }

    return isAuthenticated ? (
        children
    ) : (
        <Navigate to="/session_expired" replace state={{ from: location }} />
    );
}
