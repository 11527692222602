import {React, useState, useEffect, useMemo, useCallback} from 'react';
import './Products.css';
import {Button, Table, Modal, Tag, Row, Col, Pagination,Select as Ant_Select} from 'antd';
import {
    SyncOutlined
} from '@ant-design/icons';
import Select from "react-select";
import Tooltip from 'antd/es/tooltip';
import syncErrorIcon from '../../assets/icons/svg/syncErrorIcon.svg';
import { useStoreService } from '../..//services';
import { Notification } from '../../utilities/notification';
import { useSearchParams } from 'react-router-dom';
import ProductModal from "./ProductDetails";
import { Drawer } from "antd";


import {tab} from "@testing-library/user-event/dist/tab";
import {DateRangePicker} from "rsuite";
import {debounce} from "lodash";
const {Option}=Ant_Select;
const SyncErrorIcon = () => <img src={syncErrorIcon} alt="syncError Icon" />;



function ProductDetails() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tabledata, setTabledata] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [currentPageUrl, setCurrentPageUrl] = useState('');
    const [filter, setFilter] = useState('');
    const [isOpen, setOpen] = useState(false);
    const [productId, setProductId] = useState(null);
    const [storeId, setStoreId] = useState(null);
    


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,

    };

    const [pageSize, setPageSize] = useState(10);
    const [searchParams] = useSearchParams();
    const hasSelected = selectedRowKeys.length >= 0;
    const [modalOpen, setModalOpen] = useState(false);
    const [currentStoreSelected, setCurrentStoreSelected] = useState(null);
    const [currentStores, setCurrentStores] = useState([]);
    const [otherStores, setOtherStores] = useState([]);
    const [otherStoreSelected, setOtherStoreSelected] = useState(null);
    const [loadingStores, setLoadingStores] = useState(false);
    const [syncStatus, setSyncStatus] = useState(null);
    const [previousPageLink, setPreviousPageLink] = useState("")
    const [nextPageLink, setNextPageLink] = useState("")
    const [brandFilter, setBrandFilter] = useState("");

    const storeService = useStoreService();
    useEffect(() => {
        (async () => {
            setLoadingStores(true);
            await getMappedStoresList();
            setLoadingStores(false);
        })();
    }, []);

    useEffect(() => {
        handleOtherDropdownChange(otherStoreSelected)
    }, [filter, otherStoreSelected, brandFilter])

    const column = [
        {
            title: ''
        },
        {
            title: 'PRODUCT ID',
            dataIndex: 'product_id'

        },
        {
            title: 'Product Name',
            dataIndex: 'product'

        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            render: (text) => {
                return (
                    <div className="status">
                        {text === 'synced' ? (
                            <Tag color="#1ea97c">Synced</Tag>
                        ) : (
                            <Tag color="red">Not Synced</Tag>
                        )}
                    </div>
                )
            },
        },
        {
            title: 'VENDOR',
            dataIndex: 'vendor'
        }
    ];

    const getMappedStoresList = async () => {
        const current_store_details = storeService.getCurrentStoreDetails();
        setCurrentStoreSelected(current_store_details);

        try {
            const response = await storeService.getAllMappedStores({
                store_sync_id: current_store_details?.store_sync_id,
                type: current_store_details.type
            });

            let responseStoreData = response.data;
            console.log(responseStoreData);

            let processedData = responseStoreData?.map((item) => {
                return {
                    key: item.user_id,
                    store_url: item.store_url,
                    store_sync_id: item.store_sync_id,
                    status: [item.status]
                }
            });
            setOtherStores(processedData);
        } catch (error) {
            console.log(error);
        }
    }

    const handleSync = async () => {
        try {
            console.log("Syncing selected items:", selectedRowKeys);
            if(!selectedRowKeys || !selectedRowKeys.length){
                Notification('error', 'Select at least one product to sync');
                return;
            }

            setLoading(true);

            const response = await bulkSyncProducts(selectedRowKeys);
            if(response){
                Notification('success', 'Product Sync started successfully, please refresh after some time.');
            }
            else{
                Notification('error', 'error in Product Sync.');
            }

            setLoading(false);
        }
        catch (e){
            Notification('error', 'error in Product Sync.');
            console.log(e);
        }
    };

    const startBulkMaping = async  () => {
        try {
            console.log(tabledata);
            setModalOpen(false);

            const request_body = {
                source_sync_id: otherStoreSelected?.store_sync_id,
                destination_sync_id: currentStoreSelected?.store_sync_id
            }

            const response = await storeService.bulkSyncProducts(request_body);
            if(response){
                Notification('success', 'Sync Full Catalog started successfully, please refresh after some time.');
            }
            else{
                Notification('error', 'error in Bulk mapping.');
            }

            setLoading(false);
        }
        catch (e) {
            Notification('error', 'error in Bulk mapping.');
            console.log(e);
        }

    }

    const bulkSyncProducts = async (selectedProducts) => {
        try {

            const requestbody = {
                products_list: selectedProducts,
                source_sync_id: "",
                destination_sync_id: ""
            }

            if (currentStoreSelected?.type === 'source') {
                requestbody.source_sync_id = currentStoreSelected?.store_sync_id
                requestbody.destination_sync_id = otherStoreSelected?.store_sync_id
            } else {
                requestbody.destination_sync_id = currentStoreSelected?.store_sync_id
                requestbody.source_sync_id = otherStoreSelected?.store_sync_id
            }

            return await storeService.syncSelectedProduct(requestbody);
        }
        catch (e){
            console.log(e);
        }
    }

    const otherDropDownOptions = useMemo(() => {
        return otherStores?.map((store) => ({
            label: store.store_url,
            value: store.store_sync_id
        }));
    }, [otherStores]);

    const handleOtherDropdownChange = async (otherStoreData, action = "", page = 1, newPageSize = pageSize) => {
        setLoadingTable(true);
        setTabledata([]);
        // setTotalRecords(0)
        let requestbody = {};
        let since_id = 0;
    
        if (currentStoreSelected?.type === 'source') {
            requestbody = {
                source_sync_id: currentStoreSelected?.store_sync_id,
                destination_sync_id: otherStoreData?.store_sync_id,
                since_id: since_id,
                action: action,
                filter: filter,
                status_filter: brandFilter,
                skip_limit: (page - 1) * newPageSize,  // Adjusted skip_limit calculation
                product_status_filter: "",
                page_size: newPageSize,
            }
        } else {
            requestbody = {
                source_sync_id: otherStoreData?.store_sync_id,
                destination_sync_id: currentStoreSelected?.store_sync_id,
                since_id: since_id,
                action: action,
                filter: filter,
                status_filter: brandFilter,
                skip_limit: (page - 1) * newPageSize,  // Adjusted skip_limit calculation
                product_status_filter: "",
                page_size: newPageSize,
            }
        }
    
        try {
            const response = await storeService.getProducts(requestbody);
            console.log(response.data, "response")
            const products = response.data?.product_list;
            setTotalRecords(response?.data?.total_product_list);
            
            let processedData = products?.map((item) => ({
                key: item.product_id,
                product_id: item.product_id,
                product: item?.sku,
                status: item?.status,
                vendor: item?.source_name
            }));
            
            setCurrentPageUrl(requestbody.action);
            setPreviousPageLink(response?.data?.previous_page_link);
            setNextPageLink(response?.data?.next_page_link);
            setTabledata(processedData);
            setCurrentPage(page);
            setPageSize(newPageSize);
            setLoadingTable(false);
        } catch (error) {
            console.log(error);
            setLoadingTable(false);
            setTabledata([]);
        }
    }
    
    // const debouncedFetch = debounce(handleOtherDropdownChange(otherStoreSelected), 500);


    const handleFilterChange = async event => {
        setFilter(event.target.value?event.target.value:"");
        console.log('value is:', event.target.value);
    };

console.log("tabledata",tabledata)

    const items = [
        {
            key: '',
            label: "Select Product Status"
        },
        {
            key: 'synced',
            label: "Synced"
        },
        {
            key: 'un_synced',
            label: "Not Synced"
        }
    ];

    const handleBrandChange = (value) => {
        console.log(value)
        setBrandFilter(value);
        setCurrentPage(1);
    };
    // const showDrawer = () => {
    //     setProductId("8268418646255");
    //     setStoreId("356555257157305024");
    // };
    const handleClose = () => {
        setProductId(null);
        setStoreId(null);
        setOpen(false);
    };
    useEffect(() => {
        if (productId && storeId) {
            setOpen(true);
        }
    }, [productId, storeId]);


    return (
        <>
            {isOpen === false ?
                <>
                    <div className="content_head_ui"><h2
                        className="heading">Product </h2> {currentStoreSelected && otherStoreSelected && (
                        <Tooltip
                            title={syncStatus === 'pending' ? "Bulk mapping is in progress. Please come back after sometime." : ''}
                            placement='bottom'>
                            <Button
                                className={`bulkMapperButton primary_btn`}
                                icon={<SyncOutlined/>}
                                type="primary"
                                disabled={false}
                                onClick={() => setModalOpen(true)}
                                loading={syncStatus === 'pending'}
                            >
                                Sync Full Catalog
                            </Button>
                        </Tooltip>
                    )}
                    </div>
                    <div
                        className="storeSelection  store_dropdown_wrap"
                    >
                        <div className='top-container'>

                            <div className='dropdown-wrapper'>
                                <h3>{currentStoreSelected?.type === 'source' ? 'Destination' : 'Source'} Store

                                    {otherStoreSelected && otherStoreSelected?.type &&
                                    <Tag
                                        size="l"
                                        style={{marginLeft: 16, marginTop: 4, fontSize: '16px', padding: '4px'}}
                                        color={otherStoreSelected?.type === 'source' ? "green" : "orange"}>
                                        {otherStoreSelected?.type?.toUpperCase()}
                                    </Tag>
                                    }
                                </h3>
                                <Row className='w-100'>
                                    <div className='w-100'>
                                        <Select
                                            className='custom-select'
                                            value={otherStoreSelected ? {
                                                label: otherStoreSelected.store_url,
                                                value: otherStoreSelected.store_sync_id
                                            } : null}
                                            options={otherDropDownOptions}
                                            isLoading={loadingStores}

                                            isSearchable={false}
                                            onChange={async (data) => {
                                                const item = otherStores.find(a => a.store_sync_id === data.value);
                                                setOtherStoreSelected(item);
                                                await handleOtherDropdownChange(item);
                                            }}
                                        />
                                    </div>
                                </Row>
                            </div>
                        </div>
                        <div className="product_search_container">
                            <Row style={{width: '100%'}}>
                                <Col style={{width: '100%'}}>
                                    <div className="product_search_inner">
                                        <span style={{width: '100%'}}>
                                            <input className="custom-input" placeholder={"Search for products"} onChange={handleFilterChange}
                                                   value={filter}/>
                                        </span>
                                        {/* Brand Dropdown */}
                                        <span  className="product_status_drop" style={{marginLeft: '16px'}}>
                                        <Ant_Select
                                            showSearch
                                            placeholder="Select Product Status"
                                            optionFilterProp="children"
                                            onChange={handleBrandChange}
                                            style={{width: 200, height:50}}
                                        >
                                            {items && items.map((item, index) => {
                                                console.log(items)
                                                return (
                                                    <Option key={index}
                                                            value={item.key ? item.key : ""}>{item.label}</Option>
                                                );
                                            })}
                                        </Ant_Select>
                                    </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div style={{paddingLeft:20}} className='button-wrap'>
                            {currentStoreSelected && otherStoreSelected &&
                            <>
                                <Row>
                                    <div>
                                        <div className='ant-btn-default1'
                                             onClick={() => handleOtherDropdownChange(otherStoreSelected, currentPageUrl, currentPage)}>Refresh
                                        </div>
                                    </div>
                                </Row>
                                <Button type="primary" onClick={handleSync}>
                                    Sync Selected Items
                                </Button>


                            </>
                            }
                        </div>


                    </div>

                    <div>
                        <Modal
                            title="Sync Full Catalog"

                            style={{
                                fontSize: '20px'
                            }}
                            width={580}
                            centered
                            okText="Confirm"
                            open={modalOpen}
                            onOk={async () => {
                                await startBulkMaping();
                            }}
                            onCancel={() => setModalOpen(false)}
                            cancelButtonProps={{disabled: loading}}
                            okButtonProps={{disabled: loading, loading: loading}}
                        >
                            <p className='desc'> Once you click on confirm, the source product data will sync
                                to destination store</p>
                        </Modal>
                    </div>
                    <div className="productTable">

                    {tabledata.length > 0 ? (
                            <Table
                                className='custom-table-wrap product-table'
                                rowSelection={rowSelection}
                                columns={column}
                                dataSource={tabledata}
                                loading={loadingTable}
                                rowKey={(record) => record.product_id}
                                pagination={{
                                    current: currentPage,
                                    pageSize: 10,
                                    total: totalRecords,
                                    // showSizeChanger: true,
                                    onChange: async (page ) => {
                                        console.log(tabledata)
                                        setCurrentPage(page);
                                        handleOtherDropdownChange(otherStoreSelected, currentPageUrl, page, pageSize);
                                    }
                                }}
                                onRow={(record) => ({
                                    onClick: () => {
                                        setProductId(record.product_id);
                                        setStoreId(otherStoreSelected.store_sync_id);
                                    },
                                })}
                            />
                        ) : (
                            <div>No products found</div>
                        )}

                        {/*{*/}
                        {/*    tabledata.length > 0 &&*/}
                        {/*    (*/}
                        {/*        <div className='pagination_wrap'>*/}
                        {/*            <button onClick={() => {*/}
                        {/*                handleOtherDropdownChange(otherStoreSelected, previousPageLink, currentPage - 1)*/}
                        {/*            }} disabled={currentPage === 1 || loadingTable}>Previous*/}
                        {/*                <svg viewBox="0 0 256 512" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                    <path*/}
                        {/*                        d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/>*/}
                        {/*                </svg>*/}
                        {/*            </button>*/}

                        {/*            {!loadingTable && (*/}
                        {/*                <div className='pagination_text' style={{}}>*/}
                        {/*                    <span>Page {currentPage} of {Math.ceil(totalRecords / 50)}</span>*/}
                        {/*                </div>*/}
                        {/*            )}*/}

                        {/*            <button onClick={() => {*/}
                        {/*                handleOtherDropdownChange(otherStoreSelected, nextPageLink, currentPage + 1)*/}
                        {/*            }} disabled={currentPage === Math.ceil(totalRecords / 50) || loadingTable}>Next*/}
                        {/*                <svg viewBox="0 0 256 512" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                    <path*/}
                        {/*                        d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/>*/}
                        {/*                </svg>*/}
                        {/*            </button>*/}
                        {/*        </div>*/}
                        {/*    )*/}
                        {/*}*/}
                    </div>
                </> :
                <>
                    <Drawer
                        title={<h1 className="product-heading" >Product details</h1>}
                        placement={"right"}
                        width={"80%"}
                        onClose={handleClose}
                        open={isOpen}
                    >
                        <ProductModal
                            product_id={productId}
                            store_sync_id={storeId}
                            handleClose={handleClose}
                        />
                    </Drawer>
                </>

            }
        </>
    )
}

export default ProductDetails;
