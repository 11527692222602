import { useState, useEffect } from "react";
import { Carousel, Table, Divider, Flex, Spin, Result, Button, Tooltip } from "antd";
import { LeftCircleOutlined, RightCircleOutlined, CopyTwoTone } from "@ant-design/icons";
import { useStoreService } from "../../services";
import HTMLReactParser from "html-react-parser";

const ProductModal = (props) => {
    const { product_id, store_sync_id, handleClose } = props;
    const [dataSource, setDataSource] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hovered, setHovered] = useState("");
    const storeService = useStoreService();

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setHovered("Copied!");
        setTimeout(() => setHovered(false), 1000);
    };

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "SKU",
            dataIndex: "sku",
            key: "sku",
            render: (text) => (
                <>
                    {text || "N/A"}
                    <Tooltip placement="topRight" title={hovered}>
                        <CopyTwoTone
                            style={{ marginLeft: '8px' }}
                            onMouseOver={() => setHovered("Copy")}
                            onClick={() => {
                                copyToClipboard(text || "N/A");
                            }}
                        />
                    </Tooltip>
                </>
            ),
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await storeService.getProductsDetails({
                    product_id: product_id,
                    store_sync_id: store_sync_id,
                    store_url: ""
                });
                const productData = response?.data?.[product_id] || {};
                setDataSource(productData);
            } catch (error) {
                console.error(error);
                setError(error?.response?.status || "Error");
            } finally {
                setLoading(false);
            }
        };
        if (product_id && store_sync_id) {
            fetchData();
        }
    }, [product_id, store_sync_id]);

    const tableData = dataSource?.variants?.map((variant) => ({
        key: variant?.id,
        title: variant?.title || "N/A",
        sku: variant?.sku || "N/A",
        quantity: variant?.inventory_quantity ?? "N/A",
    })) || [];

    if (loading) {
        return (
            <Flex style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "250px" }}>
                <Spin size="large" />
            </Flex>
        );
    }

    if (error) {
        return (
            <Result
                status="error"
                title="Error"
                subTitle={`Sorry, something went wrong. (${error})`}
                extra={<Button type="primary" onClick={handleClose}>Back Home</Button>}
            />
        );
    }

    return (
        <div>
            <div className="position">
                <div className="carousel-ui">
                    <Carousel
                        arrows={true}
                        prevArrow={<LeftCircleOutlined />}
                        nextArrow={<RightCircleOutlined />}
                    >
                        {dataSource?.images?.map((image) => (
                            <div key={image.id}>
                                <img
                                    src={image.src}
                                    alt={image.alt || `image with ${image?.id}`}
                                    className="contentStyle"
                                />
                            </div>
                        )) || "No images available"}
                    </Carousel>
                </div>
                <div className="refills">
                    <h1 className="heading1">{dataSource?.title || "No Title"}</h1>
                    <div className="key-value-pair">
                        <span className="key">Product ID</span>
                        <span className="dot">:</span>
                        <span className="value">{dataSource?.id}</span>
                    </div>
                    <div className="key-value-pair">
                        <span className="key">Product Status</span>
                        <span className="dot">:</span>
                        <span className="value">{dataSource?.status}</span>
                    </div>
                    <div className="key-value-pair">
                        <span className="key">Vendor</span>
                        <span className="dot">:</span>
                        <span className="value">{dataSource?.vendor || "N/A"}</span>
                    </div>
                    <Divider />
                    <div className="product_variant_outer">
                        <h1 className="heading1">Variants</h1>
                        <Table
                            dataSource={tableData}
                            columns={columns}
                            pagination={false}
                            scroll={{ x: true }}
                        />
                    </div>
                    <Divider />
                    <div>
                        <h1 className="heading1">Description</h1>
                        <div className="description-inner-container">
                            {typeof dataSource?.body_html === "string"
                                ? HTMLReactParser(dataSource.body_html.toString())
                                : "No description available"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductModal;
