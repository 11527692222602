import useFetchWrapper from "../helpers/FetchWrapper";


function useOrderService() {

    const fetchWrapper = useFetchWrapper();
    const baseUrl = `${process.env.REACT_APP_API_URL}/orders`;

    return {
        getOrderList,
        getCheckoutLineItems,
        storeExist,
        togglePaid,
        getBrandsList,
        getOrderDetails,
        createOrderAtSource
    }

    async function togglePaid(query){
        try{
            const res = await fetchWrapper.post(`${baseUrl}/toggle_paid`, query)
            return res.data
        }
        catch (e){
            console.log(e);
            return false;
        }
    }


    async function getOrderList(queries){
        try {
            const list = await fetchWrapper.post(`${baseUrl}/getOrdersList`, queries);
            return list?.data;
        } catch (err){
            console.log(err);
        }
    }

    async function getBrandsList(){
        try {
            const list = await fetchWrapper.get(`${baseUrl}/getBrandsList`);
            return list?.data;
        } catch (err){
            console.log(err);
        }
    }


    async function getCheckoutLineItems(queries){
        try{
            const list = await fetchWrapper.post(`${baseUrl}/get_checkout_line_items`, {
               destination_order_id: queries?.destination_order_id
            });

            return list?.data;
        }
        catch (err){
            console.log(err);
        }
    }

    async function storeExist(store_url){
        try{
            const res = await fetchWrapper.post(`${baseUrl}/store_exist`, {
                store_url: store_url
            });
            return res?.data;
        }
        catch (e){
            console.log(e);
        }
    }

    async function getOrderDetails(queries){
        try {
            const list = await fetchWrapper.post(`${baseUrl}/details`, queries);
            return list?.data;
        } catch (err){
            console.log(err);
        }
    }
    async function createOrderAtSource(queries){
        try {
            const list = await fetchWrapper.post(`${baseUrl}/create/source/manual`, queries);
            return list?.data;
        } catch (err){
            console.log(err);
        }
    }
}

export { useOrderService };