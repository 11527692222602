import React, { useEffect, useState } from 'react';
import { Spin, Card, Typography, Row, Col, Divider, Button, Tooltip, message, Space } from 'antd';
import { SyncOutlined, ShopOutlined, KeyOutlined, CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useStoreService } from '../../services';
import './Home.css';

const { Title, Text } = Typography;

function Home() {
    const storeService = useStoreService();
    const [loading, setLoading] = useState(false);
    const [storeDetails, setStoreDetails] = useState(null);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getStoreDetails();
            setLoading(false);
        })();
    }, []);

    const getStoreDetails = async () => {
        try {
            const store_details = storeService.getCurrentStoreDetails();
            const response = await storeService.getStoreDetails(store_details?.store_url);

            if (response && response?.data) {
                setStoreDetails(response?.data);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        message.success('Copied to clipboard!');
    };

    return (
        <div className="home-container" style={{ padding: '40px', backgroundColor: '#f4f6f8', minHeight: '100vh' }}>
            <Spin spinning={loading}>
                <Space direction="vertical" size="large" style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
                    <Card
                        className="welcome-card"
                        style={{
                            borderRadius: '8px',
                            padding: '32px',
                            backgroundColor: '#ffffff',
                            border: '1px solid #e0e0e0',
                            textAlign: 'left', // Align text to the left
                        }}
                    >
                        <Row gutter={16} align="middle">
                            {/*<Col>*/}
                            {/*    <ShopOutlined style={{ fontSize: '40px', color: '#1890ff' }} />*/}
                            {/*</Col>*/}
                            <Col>
                                <Title level={3} style={{ margin: '0 0 16px 0', color: '#333' }}>
                                    Welcome
                                </Title>
                                <Text style={{ fontSize: '16px', color: '#595959' }}>
                                    Manage and sync your stores effortlessly. Keep track of product imports and orders with ease.
                                </Text>
                            </Col>
                        </Row>
                    </Card>

                    {storeDetails && (
                        <Card
                            className="store-details-card"
                            style={{
                                borderRadius: '8px',
                                padding: '32px',
                                backgroundColor: '#ffffff',
                                border: '1px solid #e0e0e0',
                            }}
                        >
                            <Title level={4} style={{ marginBottom: '24px', color: '#333' }}>
                                <SyncOutlined style={{ marginRight: '8px', color: '#52c41a' }} />
                                Store Details
                            </Title>
                            <Divider />
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Text strong>Store Name:</Text>
                                    <Text style={{ display: 'block', color: '#595959' }}>{storeDetails.store_url}</Text>
                                </Col>
                                <Col span={12}>
                                    <Text strong>Store Type:</Text>
                                    <Text style={{ display: 'block', color: '#595959' }}>{storeDetails.type}</Text>
                                </Col>
                                <Col span={12}>
                                    <Text strong>Joined Date:</Text>
                                    <Text style={{ display: 'block', color: '#595959' }}>
                                        {moment(storeDetails.created_at * 1000).format('MMMM Do YYYY, h:mm:ss A')}
                                    </Text>
                                </Col>
                            </Row>
                        </Card>
                    )}

                    <Card
                        className="source-stores-card"
                        style={{
                            borderRadius: '8px',
                            padding: '32px',
                            backgroundColor: '#ffffff',
                            border: '1px solid #e0e0e0',
                        }}
                    >
                        <Title level={4} style={{ marginBottom: '24px', color: '#333' }}>
                            <KeyOutlined style={{ marginRight: '8px', color: '#faad14' }} />
                            Sync with Source Stores
                        </Title>
                        <Divider />
                        <Text style={{ fontSize: '16px', color: '#595959' }}>
                            Share this key with Source stores to sync products and manage orders efficiently. Ensure smooth imports and updates across multiple stores.
                        </Text>
                        <Text
                            style={{
                                display: 'block',
                                marginTop: '16px',
                                fontSize: '18px',
                                fontWeight: 'bold',
                                color: '#1890ff'
                            }}
                        >
                            {storeDetails?.store_sync_id}
                        </Text>
                        <Tooltip title="Copy Sync Key">
                            <Button
                                type="link"
                                icon={<CopyOutlined />}
                                style={{ marginTop: '8px', padding: 0 }}
                                onClick={() => copyToClipboard(storeDetails?.store_sync_id)}
                            />
                        </Tooltip>
                    </Card>
                </Space>
            </Spin>
        </div>
    );
}

export default Home;
