import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Row, Typography, Space } from 'antd';
import {SyncOutlined, ShopOutlined, RightCircleOutlined, AppstoreAddOutlined} from '@ant-design/icons';
import React, { useState } from "react";
import { useStoreService } from "../services";
import { useWebhookSerive } from "../services/webhooks.service";
import { useUserService } from "../services";
import { Notification } from "../utilities/notification";

const { Title, Text } = Typography;

const StoreDetails = ({ store }) => {
    const [searchParams] = useSearchParams();
    const [shop, setShop] = useState();
    const storeService = useStoreService();
    const webhookService = useWebhookSerive();
    const userService = useUserService();
    const navigate = useNavigate();

    const getQueryParams = () => {
        const hmac = searchParams.get('hmac');
        const host = searchParams.get('host');
        const shop = searchParams.get('shop');
        const timestamp = searchParams.get('timestamp');
        return { hmac, host, shop, timestamp };
    };

    const addStoreType = async (store_type) => {
        try {
            const store_details = storeService.getCurrentStoreDetails();
            let store = {
                store_sync_id: store_details?.store_sync_id || "",
                type: store_type
            };

            const response = await storeService.addStoreType(store);

            if (store_type === "destination") {
                await storeService.destinationOrderType({
                    store_sync_id: store_details.store_sync_id || "",
                    type: "manual"
                });
            }

            const webhooks_response = await webhookService.addWebhooks(store_details?.store_url);

            if (webhooks_response?.status === 200) {
                const login_details_response = await userService.login(store_details?.store_url);
                const login_details = login_details_response?.data;

                if (!login_details) {
                    Notification('error', "Error encountered while login");
                    return;
                }
            } else {
                Notification('error', "Error encountered while adding store type");
                return;
            }

            navigate('/');
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="content_wrapper" style={{ textAlign: 'center', padding: '40px 20px' }}>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Title level={2}>
                    <ShopOutlined style={{ fontSize: '48px', color: '#4A43A7' }} />
                </Title>
                <Title level={1} style={{ fontSize: '48px', margin: 0 }}>NICK</Title>
                <Text type="secondary" style={{ fontSize: '18px' }}>Select Source and Destination</Text>

                <Row gutter={[32, 32]} justify="center" align="middle" style={{ marginTop: '40px' }}>
                    <Col xs={24} sm={12} lg={8}>
                        <Card
                            hoverable
                            title={<span><ShopOutlined /> Source</span>}
                            bordered={false}
                            style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
                        >
                            <Text>Connect your primary store as the source.</Text>
                        </Card>
                        <Button
                            onClick={() => addStoreType("source")}
                            type="primary"
                            icon={<RightCircleOutlined />}
                            style={{ marginTop: '20px' }}
                            block
                        >
                            Add As Source
                        </Button>
                    </Col>

                    <Col xs={24} sm={12} lg={8}>
                        <Card
                            hoverable
                            title={<span><ShopOutlined /> Destination</span>}
                            bordered={false}
                            style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
                        >
                            <Text>Connect your secondary store as the destination.</Text>
                        </Card>
                        <Button
                            onClick={() => addStoreType("destination")}
                            type="primary"
                            icon={<RightCircleOutlined />}
                            style={{ marginTop: '20px' }}
                            block
                        >
                            Add As Destination
                        </Button>
                    </Col>
                </Row>
            </Space>
        </div>
    );
};

export default StoreDetails;
