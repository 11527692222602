import { useState, useEffect, useMemo, useCallback } from 'react';
import './Orders.css';
import {Table, Modal, Row, Switch, Select, Col, Button, Input, Typography, Tag} from 'antd';
// const { Search } = Input;
// const { Option } = Select;
import syncErrorIcon from '../../assets/icons/svg/syncErrorIcon.svg';
import { useStoreService, useOrderService } from '../..//services';
import { Notification } from '../../utilities/notification';
import type, { MenuProps } from 'antd';
import Papa from 'papaparse';

import {debounce} from 'lodash';
import {
    CheckCircleTwoTone, CloseCircleTwoTone,
    SyncOutlined
} from '@ant-design/icons';
import {Option} from "antd/es/mentions";
import axios from "axios";
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.css"
import Tooltip from "antd/es/tooltip";

const SyncErrorIcon = () => <img src={syncErrorIcon} alt="syncError Icon" />;


function OrderDetails() {
    const [tabledata, setTabledata] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [addShippingmodal, setAddShippingModal] = useState(false);
    const [currentStoreSelected, setCurrentStoreSelected] = useState(null);
    const [currentStores, setCurrentStores] = useState([]);
    const [loadingStores, setLoadingStores] = useState(false);
    const[currentCheckoutList, setCurrentCheckoutList] = useState([]);
    const[currentDestination, setCurrentDestinantion] = useState();
    const [distSyncId,setDistSyncId] = useState();
    const [totalPages, setTotalPages] = useState(0);
    const [filter, setFilter] = useState(null);
    const [brandFilter, setBrandFilter] = useState(0);
    const [payFilter, setPayFilter] = useState(0);
    const [csvOutput,setCsvOutput]=useState(null);
    const [startDate,setStartDate]=useState(null);
    const [endDate,setEndDate]=useState(null);
    const [brandItems, setBrandItems]=useState([]);
    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }

    const storeService = useStoreService();
    const orderService = useOrderService();

    const current_store_details = storeService.getCurrentStoreDetails();
    console.log(current_store_details)

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [shippingFees, setShippingFees] = useState({});
    const [loadingPlaceOrder, setLoadingPlaceOrder] = useState(false);
    const [loadingPerSource, setLoadingPerSource] = useState({});
    const [orderDetails, setOrderDetails] = useState({});
    const [currentRecord, setCurrentRecord] = useState({});
    const[loading,setLoading]=useState(false);

    useEffect(() => {
        (async () => {
            const payload = {
                store_sync_id: current_store_details?.store_sync_id,
                store_url: current_store_details?.store_url,
            }
            if(!payload.store_sync_id){
                return;
            }
             const order_list = await orderService.getOrderList(payload);
            console.log(order_list)
            console.log("order_list", order_list)
            setTabledata(order_list['orders']);
            setTotalPages(order_list['total_count'])
        })();
    }, [loadingPlaceOrder , loadingPerSource]);


    useEffect(() => {
        (async () => {
            setLoadingStores(true);
            const current_store_details = storeService.getCurrentStoreDetails();
            console.log(current_store_details)
            setCurrentStoreSelected(current_store_details);

            const destination_sync_id = current_store_details?.store_sync_id;
            console.log("In",destination_sync_id)
            setCurrentStoreSelected({ store_sync_id: destination_sync_id, type: 'destination'});

            await handleCurrentDropdownChange();
            setLoadingStores(false);
        })();
    }, [currentPage, brandFilter, payFilter, startDate, endDate, setCurrentStoreSelected]);

    useEffect(() => {
        // Skip the initial call (when the component mounts) if searchTerm is empty

        let timerId;
        // Call the debounced function after a delay
       if(filter!==null){
           timerId = setTimeout(() => {
               debouncedFetch();
               setCurrentPage(1)
           }, 300);
       } else if(filter===''){
           clearTimeout(timerId);
           timerId = setTimeout(() => {
               debouncedFetch();
               setCurrentPage(1)
           }, 300);
       }

        // Clean-up function to cancel any pending debounced calls
        return () => {
            clearTimeout(timerId);
        };
    }, [filter, brandItems]);


    async function handleSubmitChange(mapped_source_checkouts, destination_order_details){
        try {
            const checkout_line_items = await orderService.getCheckoutLineItems({"destination_order_id": destination_order_details?.destination_order_id})
            console.log(checkout_line_items);

            setCurrentCheckoutList(checkout_line_items);

            setAddShippingModal(true)
        }
        catch (e){
            console.log(e)
        }
    }

    const onSwitchChange = async (checked, source_order_id) => {
        // console.log(`switch to ${checked}`, source_order_id);
        const res = await orderService.togglePaid({
            'paid': !checked,
            'source_order_id': source_order_id
        })
        // console.log(res);
        if(!res){
            Notification('error', 'Something went wrong please refresh and try again')
        }
        else{
            Notification('success', 'Payment information updated')
        }
        return !checked
    }

    const [loadingOrderDetails, setLoadingOrderDetails] = useState({});
    const getOrderDetails = async (record) => {
        setOrderDetails({});
        const orderId = record.destination_order_id;
        setLoading(true)
        // Set loading state for the specific order
        setLoadingOrderDetails(prev => ({ ...prev, [orderId]: true }));
        setCurrentRecord(record);
        console.log(record);
        const payload = {
            order_id: record.destination_order_id,
            store_sync_id: record.destination_sync_id
        };
        const res = await orderService.getOrderDetails(payload);
        console.log(res);
        setOrderDetails(res);
        // setOrderDetails({
        //         "1234567890+source1.myshopify.com": [
        //             {
        //                 "source_product_id": "1111111111",
        //                 "destination_product_id": "2222222222",
        //                 "source_variant_id": 101010101010,
        //                 "quantity": 2,
        //                 "price": "49.95",
        //                 "sku": "SKU001",
        //                 "variant_title": "Red",
        //                 "product_title": "Awesome T-Shirt",
        //                 "source_url": "source1.myshopify.com"
        //             },
        //             {
        //                 "source_product_id": "1111111112",
        //                 "destination_product_id": "2222222223",
        //                 "source_variant_id": 101010101011,
        //                 "quantity": 1,
        //                 "price": "89.95",
        //                 "sku": "SKU002",
        //                 "variant_title": "Blue",
        //                 "product_title": "Cool Jacket",
        //                 "source_url": "source1.myshopify.com"
        //             }
        //         ],
        //         "0987654321+source2.myshopify.com": [
        //             {
        //                 "source_product_id": "3333333333",
        //                 "destination_product_id": "4444444444",
        //                 "source_variant_id": 202020202020,
        //                 "quantity": 3,
        //                 "price": "29.99",
        //                 "sku": "SKU003",
        //                 "variant_title": "Small",
        //                 "product_title": "Fancy Hat",
        //                 "source_url": "source2.myshopify.com"
        //             },
        //             {
        //                 "source_product_id": "3333333334",
        //                 "destination_product_id": "4444444445",
        //                 "source_variant_id": 202020202021,
        //                 "quantity": 2,
        //                 "price": "19.99",
        //                 "sku": "SKU004",
        //                 "variant_title": "Large",
        //                 "product_title": "Stylish Scarf",
        //                 "source_url": "source2.myshopify.com"
        //             }
        //         ],
        //         "5678901234+source3.myshopify.com": [
        //             {
        //                 "source_product_id": "5555555555",
        //                 "destination_product_id": "6666666666",
        //                 "source_variant_id": 303030303030,
        //                 "quantity": 1,
        //                 "price": "199.99",
        //                 "sku": "SKU005",
        //                 "variant_title": "Leather",
        //                 "product_title": "Premium Wallet",
        //                 "source_url": "source3.myshopify.com"
        //             },
        //             {
        //                 "source_product_id": "5555555556",
        //                 "destination_product_id": "6666666667",
        //                 "source_variant_id": 303030303031,
        //                 "quantity": 4,
        //                 "price": "59.99",
        //                 "sku": "SKU006",
        //                 "variant_title": "Metal",
        //                 "product_title": "Modern Watch",
        //                 "source_url": "source3.myshopify.com"
        //             }
        //         ],
        //         "6789012345+source4.myshopify.com": [
        //             {
        //                 "source_product_id": "7777777777",
        //                 "destination_product_id": "8888888888",
        //                 "source_variant_id": 404040404040,
        //                 "quantity": 5,
        //                 "price": "9.99",
        //                 "sku": "SKU007",
        //                 "variant_title": "Plastic",
        //                 "product_title": "Eco-friendly Bottle",
        //                 "source_url": "source4.myshopify.com"
        //             },
        //             {
        //                 "source_product_id": "7777777778",
        //                 "destination_product_id": "8888888889",
        //                 "source_variant_id": 404040404041,
        //                 "quantity": 6,
        //                 "price": "39.99",
        //                 "sku": "SKU008",
        //                 "variant_title": "Wood",
        //                 "product_title": "Sustainable Sunglasses",
        //                 "source_url": "source4.myshopify.com"
        //             }
        //         ]
        //     }
        // );
        setLoadingOrderDetails(prev => ({ ...prev, [orderId]: false }));
        setIsModalVisible(true);
        setLoading(false)

    };


    const handlePlaceOrderPerSource = async (sourceKey) => {
        setLoadingPerSource((prev) => ({ ...prev, [sourceKey]: true }));
        try {
            // Filter out placed variants
            const unplacedItems = orderDetails[sourceKey].filter(item => !item.variant_placed);

            if (unplacedItems.length === 0) {
                Notification('info', 'This source is already placed.');
                setLoadingPerSource((prev) => ({ ...prev, [sourceKey]: false }));
                return;
            }

            const payload = {
                [sourceKey]: {
                    ...unplacedItems.reduce((acc, item) => {
                        const productId = item.source_product_id;
                        if (!acc[productId]) {
                            acc[productId] = [];
                        }
                        acc[productId].push({
                            ...item,
                            fee: shippingFees[sourceKey]
                        });
                        return acc;
                    }, {}),
                    fee: shippingFees[sourceKey],
                }
            };

            console.log("Single Order Payload", payload);
            const res = await orderService.createOrderAtSource({
                payload: payload,
                destination_details: {
                    destination_order_id: currentRecord.destination_order_id,
                    destination_store_url: tabledata[0].destination_store_url, // Placeholder for actual destination store URL
                    destination_sync_id: tabledata[0].destination_sync_id // Placeholder for actual destination sync ID
                }
            });

            if (!res.error) {
                Notification('success', 'Order placed successfully!');
                setIsModalVisible(false);
                setShippingFees({});
            } else {
                Notification('error', res.message || "An error Occured");
            }
        } catch (error) {
            console.error(error);
            Notification('error', 'Failed to place the order');
        } finally {
            setLoadingPerSource((prev) => ({ ...prev, [sourceKey]: false }));
        }
    };


    const handlePlaceOrderAll = async () => {
        setLoadingPlaceOrder(true);
        try {
            const allPayloads = Object.keys(orderDetails).reduce((acc, sourceKey) => {
                // Filter out placed variants
                const unplacedItems = orderDetails[sourceKey].filter(item => !item.variant_placed);

                if (unplacedItems.length > 0) {
                    acc[sourceKey] = {
                        ...unplacedItems.reduce((sourceAcc, item) => {
                            const productId = item.source_product_id;
                            if (!sourceAcc[productId]) {
                                sourceAcc[productId] = []; // Initialize an array if the product ID is not yet added
                            }
                            sourceAcc[productId].push({
                                ...item,
                                fee: shippingFees[sourceKey]  // Append the shipping fee for each variant
                            });
                            return sourceAcc;
                        }, {}),
                        fee: shippingFees[sourceKey],  // Global fee for the source
                    };
                }

                return acc;
            }, {});

            // If no unplaced sources remain, show a notification and return early
            if (Object.keys(allPayloads).length === 0) {
                Notification('info', 'All sources are already placed.');
                setLoadingPlaceOrder(false);
                return;
            }

            console.log("All Order Payload", allPayloads);
            console.log("Order Details", currentRecord);
            // return
            const res = await orderService.createOrderAtSource({
                payload: allPayloads,
                destination_details: {
                    destination_order_id: currentRecord.destination_order_id,
                    destination_store_url: tabledata[0].destination_store_url, // Placeholder for actual destination store URL
                    destination_sync_id: tabledata[0].destination_sync_id // Placeholder for actual destination sync ID
                }
            });
            console.log("Res",res)

            if (!res.error) {
                Notification('success', 'All orders placed successfully!');
                setIsModalVisible(false);
                setShippingFees({});
            } else {
                Notification('error', res.message || "An error Occured");
            }
        } catch (error) {
            console.error(error);
            Notification('error', 'Failed to place all orders');
        } finally {
            setLoadingPlaceOrder(false);
        }
    };

    const renderOrderDetailsModal = () => {
        // Determine if there are any unplaced sources
        const hasUnplacedSources = Object.keys(orderDetails).some(sourceKey =>
            orderDetails[sourceKey].some(item => !item.variant_placed && !item.isDeleted)
        );

        return (
            <Modal
                title="Order Details"
                visible={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false);
                    setShippingFees({});
                    setCurrentRecord({});
                }}
                footer={null}
                width={1000}
                bodyStyle={{ maxHeight: '70vh', overflowY: 'auto' }}
            >
                {Object.keys(orderDetails).map((sourceKey, index) => {
                    const [storeId, storeUrl] = sourceKey.split('+');

                    // Check if all variants are placed for this source
                    const allVariantsPlaced = orderDetails[sourceKey].every(item => item.variant_placed);

                    // Check if any variant is deleted
                    const hasDeletedVariants = orderDetails[sourceKey].some(item => item.isDeleted);

                    return (
                        <div
                            key={index}
                            style={{
                                marginBottom: '20px',
                                padding: '10px',
                                border: '1px solid #f0f0f0',
                                borderRadius: '8px',
                                backgroundColor: allVariantsPlaced ? '#e6fffb' : '#fff',
                            }}
                        >
                            <Row gutter={16}>
                                {/* Left Column: Product Details */}
                                <Col span={16}>
                                    <Typography.Text
                                        strong
                                        style={{ display: 'block', marginBottom: '10px' }}
                                    >
                                        Source: {storeUrl}{' '}
                                        {allVariantsPlaced && (
                                            <Tooltip title="All variants placed">
                                                <CheckCircleTwoTone twoToneColor="#52c41a" />
                                            </Tooltip>
                                        )}
                                    </Typography.Text>
                                    {orderDetails[sourceKey].map((item, idx) => (
                                        <div
                                            key={idx}
                                            style={{
                                                marginBottom: '10px',
                                                paddingLeft: '20px',
                                                borderLeft: '2px solid #1890ff',
                                            }}
                                        >
                                            <Typography.Text
                                                strong
                                                style={{
                                                    fontSize: '16px',
                                                    color: item.isDeleted ? 'red' : '#1890ff',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                #{idx + 1}: {item.product_title}{' '}
                                                {item.variant_placed && !item.isDeleted && (
                                                    <Tooltip title="This variant is placed">
                                                        <CheckCircleTwoTone
                                                            twoToneColor="#52c41a"
                                                            style={{ marginLeft: '10px' }}
                                                        />
                                                    </Tooltip>
                                                )}
                                                {item.isDeleted && (
                                                    <Tooltip title="This variant has been deleted">
                                                        <CloseCircleTwoTone
                                                            twoToneColor="#ff4d4f"
                                                            style={{ marginLeft: '10px' }}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </Typography.Text>
                                            <p>
                                                <strong>Variant:</strong> {item.variant_title}
                                            </p>
                                            <p>
                                                <strong>Price:</strong> {item.price}
                                            </p>
                                            <p>
                                                <strong>Quantity:</strong> {item.quantity}
                                            </p>
                                            {item.isDeleted && (
                                                <p style={{ color: 'red' }}>
                                                    This variant has been deleted and cannot be ordered.
                                                </p>
                                            )}
                                        </div>
                                    ))}
                                </Col>

                                {/* Right Column: Place Order Section */}
                                <Col span={8}>
                                    {allVariantsPlaced ? (
                                        <Typography.Text type="success" strong>
                                            This source is already placed.
                                        </Typography.Text>
                                    ) : hasDeletedVariants ? (
                                        <Typography.Text type="danger" strong>
                                            Cannot place order due to deleted variant(s).
                                        </Typography.Text>
                                    ) : (
                                        <>
                                            <Input
                                                placeholder="Enter shipping fee"
                                                onChange={(e) =>
                                                    setShippingFees((prev) => ({
                                                        ...prev,
                                                        [sourceKey]: e.target.value,
                                                    }))
                                                }
                                                value={shippingFees[sourceKey] || ''}
                                                style={{ marginBottom: '10px', width: '100%' }}
                                            />
                                            <Button
                                                type="primary"
                                                loading={loadingPerSource[sourceKey]}
                                                onClick={() => handlePlaceOrderPerSource(sourceKey)}
                                                style={{ width: '100%' }}
                                                disabled={hasDeletedVariants}
                                            >
                                                Place Order
                                            </Button>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    );
                })}
                {hasUnplacedSources && (
                    <Button
                        type="primary"
                        loading={loadingPlaceOrder}
                        onClick={handlePlaceOrderAll}
                        style={{ marginTop: '20px', width: '100%' }}
                        disabled={Object.keys(orderDetails).some(sourceKey =>
                            orderDetails[sourceKey].some(item => item.isDeleted)
                        )}
                    >
                        Place Order for All Sources
                    </Button>
                )}
            </Modal>
        );
    };

    const column = [
        {
            title: 'Dest Order ID',
            dataIndex: 'destination_order_id',
            width: 110
        },
        {
            title: 'Dest Order Number',
            dataIndex: 'destination_order_number',
        },
        // {
        //     title: 'Source Order ID',
        //     dataIndex: 'source_order_id',
        //
        // },
        // {
        //     title: 'Source Order Number',
        //     dataIndex: 'source_order_number',
        // },
        // {
        //     title: 'Source Store URL',
        //     dataIndex: 'source_store_url',
        // },
        // {
        //     title: 'Source Order Created At',
        //     dataIndex: 'source_order_created_at',
        //     render: (_, { source_order_created_at }) => (
        //         <>
        //         <span className="tooltiptext">
        //             {new Date(source_order_created_at)?.toLocaleDateString('en-US', {
        //                 month: '2-digit',
        //                 day: '2-digit',
        //                 year: 'numeric'
        //             })}
        //         </span>
        //         </>
        //     )
        // },
        {
            title: 'Order Date',
            dataIndex: 'destination_order_created_at',
            render: (_, { destination_order_created_at }) => (
                <>
                <span className="tooltiptext">
                    {new Date(destination_order_created_at)?.toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                    })}
                </span>
                </>
            ),
            width: 100
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text) => {
                if (text === "placed") {
                    return (
                        <Tag title={text} icon={<CheckCircleTwoTone twoToneColor="#1ea97c" />} color="#1ea97c">
                            {"Placed"}
                        </Tag>
                    );
                }else if (text === "unplaced") {
                    return (
                        <Tag title={text} icon={<SyncOutlined twoToneColor="#eb2f96" spin/>} color="error">
                            {"Unplaced"}
                        </Tag>
                    );
            }else {
                return (
                    <Tag title={text} icon={<SyncOutlined spin/>} color="processing">
                        {"Partially Placed"}
                    </Tag>
                );
                }
            },
            width: 160,

        },
        {
            title: 'Total Price',
            dataIndex: 'total_price',
            render: (text) => (
                <p>{parseFloat(text)?.toFixed(2)} {text ? "INR" : ""}</p>
            )
        },
        {
            title: 'Subtotal',
            dataIndex: 'subtotal_price',
            render: (text) => (
                <p>{parseFloat(text)?.toFixed(2)} {text ? "INR" : ""}</p>
            )
        },
        {
            title: 'Total Tax',
            dataIndex: 'total_tax',
            render: (text) => (
                <p>{parseFloat(text)?.toFixed(2)} {text ? "INR" : ""}</p>
            )
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
        },
        {
            title: 'Financial Status',
            dataIndex: 'financial_status',
        },
        {
            title: 'Fulfillment Status',
            dataIndex: 'fulfillment_status',
            render: (text) => (
                <p>{text ?? "Unfulfilled"}</p>
            )
        },
        {
            title: 'Payment Gateway',
            dataIndex: 'payment_gateway_names',
            render: (text) => (
                <p>{text?.join(', ')}</p>
            )
        },
        {
            title: "Actions",
            dataIndex: "actions",
            fixed: 'right',
            width: 180,
            render: (text, record) => {
                const orderId = record.destination_order_id;
                return (
                    <Button
                        size="middle"
                        disabled={loadingOrderDetails[orderId] || loading}
                        loading={loadingOrderDetails[orderId]}  // Loading state for the specific order
                        onClick={() => getOrderDetails(record)}
                    >
                        {record?.status === "placed" ? "View Order Details" : "Place Order"}
                    </Button>
                );
            }
        }
    ];



    const handleCurrentDropdownChange = async () => {
        console.log("RUNING")

        setLoadingTable(true);
        setTabledata([]);
        // console.log(currentPage, "page", Math.max(currentPage - 1, 0))
        const requestbody = {
            "page": Math.max(currentPage - 1, 0),
            "destination_sync_id" : currentStoreSelected?.store_sync_id,
            store_sync_id: currentStoreSelected?.store_sync_id,
            'search': filter,
            'brandFilter': brandFilter,
            'status': payFilter,
            'startDate': startDate,
            'endDate': endDate
        }
        console.log(requestbody, "requestBody")
        // console.log(currentStoreSelected?.store_sync_id);
        setDistSyncId(currentStoreSelected?.store_sync_id)
        try {
            console.log(requestbody, "requestbody")
            if(!requestbody.destination_sync_id || !requestbody.store_sync_id){
                setLoadingTable(false);
                return;
            }
            const response = await orderService.getOrderList(requestbody);
            if(response) {
                setTotalPages(response['total_count'])
                console.log(response, "orders list");
                // setTotalRecords(response?.length);
                setTabledata(response['orders'])
                // let processedData = response.list.map((item, index) => {
                //     return {
                //         key: index,
                //         mapped_source_orders: {
                //             order_status_url: item.order_status_url,
                //             source_order_number: item.source_order_number
                //         },
                //         destination_order_id: item.destination_order_id,
                //         destination: item.destination,
                //         source_order_id: item.source_order_id,
                //         source_sync_id: item.source_sync_id,
                //         source_store_url: item.source_store_url,
                //         source_order_created_at: item.source_order_created_at,
                //         cms_external_id: item.cms_external_id,
                //         brand_id: item.brand_id,
                //         brand_name: item.brand_name,
                //         paid: item.paid || false,
                //         merchant_cost: item.merchant_cost || 0,
                //         shipping_cost: item.shipping_cost || 0
                //     }
                // });
            }

            // console.log(processedData, "processed data");

            // let brand_list_res = await orderService.getBrandsList();
            // if(brand_list_res) {
            //     console.log(brand_list_res, "brand_list_res")
            //     brand_list_res = [{brand_id: 0, brand_name: "Select Brand"}].concat(brand_list_res)
            //
            //     console.log(JSON.stringify(brand_list_res), "brand_list_res")
            //     let brand_items = brand_list_res.map((brand_obj) => {
            //         return {
            //             key: brand_obj.brand_id,
            //             label: brand_obj.brand_name
            //         }
            //     })
            //     console.log(brand_items, "brand_items")
            //     setBrandItems(brand_items)
            // }


            setLoadingTable(false);
            // setTabledata(processedData);
            // console.log(response['page_count'])
            // setCurrentPage(currentPage);
        } catch (error) {
            console.log(error);
            setLoadingTable(false);
            setTabledata([]);
        }
    }


    const debouncedFetch = debounce(handleCurrentDropdownChange, 300);

    const handleFilterChange = async event => {
        setFilter(event.target.value?event.target.value:"");
        console.log('value is:', event.target.value);
    };



    // let brand_items = [
    //     {
    //         key: '',
    //         label: "Select Brand"
    //     }
    // ];

    let items = [
        {
            key: '',
            label: "Select status"
        },
        {
            key: 'placed',
            label: "Placed"
        },
        {
            key: 'unplaced',
            label: "Unplaced"
        },
        {
            key: 'partially_placed',
            label: "Partially Placed"
        },

    ];

    const handleBrandChange = (value) => {
        console.log(value)
        setBrandFilter(value);
        setCurrentPage(1);
    };

    const handlePaymentChange = (value) => {
        console.log(value)
        setPayFilter(value);
        setCurrentPage(1);
    };

    // const convertToCsv = (jsonData) => {
    //     const csv = Papa.unparse(jsonData);
    //     const csvData = new Blob([csv], { type: 'text/csv' });
    //     const csvUrl = URL.createObjectURL(csvData);
    //     const downloadLink = document.createElement('a');
    //     downloadLink.href = csvUrl;
    //     downloadLink.download = 'output.csv';
    //     document.body.appendChild(downloadLink);
    //     downloadLink.click();
    //     document.body.removeChild(downloadLink);
    // };

    const convertToCsv = (jsonData) => {
        const formattedData = jsonData.flat().map(order => {
            const {
                destination_order_id,
                destination_order_number,
                destination_order_created_at,
                total_price,
                subtotal_price,
                total_tax,
                currency,
                financial_status,
                fulfillment_status,
                payment_gateway_names,
                customer_details: { first_name, last_name, email },
                shipping_address: { address1, city, zip, country }
            } = order;

            // Flatten the source_dest_variant_map for easy CSV formatting
            const variants = Object.values(order.source_dest_variant_map).flat();
            return variants.map(variant => ({
                "Destination Order ID": destination_order_id,
                "Destination Order Number": destination_order_number,
                "Order Date": destination_order_created_at,
                "Total Price": total_price,
                "Subtotal": subtotal_price,
                "Total Tax": total_tax,
                "Currency": currency,
                "Financial Status": financial_status,
                "Fulfillment Status": fulfillment_status ?? "Unfulfilled",
                "Payment Gateway": payment_gateway_names.join(', '),
                "Customer Name": `${first_name} ${last_name}`,
                "Customer Email": email,
                "Shipping Address": `${address1}, ${city}, ${zip}, ${country}`,
                "Product Title": variant.product_title,
                "Variant Title": variant.variant_title,
                "Quantity": variant.quantity,
                "Price": variant.price,
                "SKU": variant.sku || "N/A",
                "Source Store URL": variant.source_url
            }));
        }).flat();

        const csv = Papa.unparse(formattedData);
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const downloadLink = document.createElement('a');
        downloadLink.href = csvUrl;
        downloadLink.download = 'output.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const getAllData=async()=>{
        let data=[];
        let requestbody = {
            "page": Math.max(currentPage - 1, 0),
            "destination_sync_id" : currentStoreSelected?.store_sync_id,
            store_sync_id: currentStoreSelected?.store_sync_id,
            'search': filter,
            'brandFilter': brandFilter,
            'status': payFilter,
            'startDate': startDate,
            'endDate': endDate

        }
        console.log("rew",requestbody)
        let jsonInput=[];
        let page=Math.ceil(totalPages/10);
        console.log("page",page)
        for(let i=0;i<page;i++){
            requestbody={...requestbody,page:i};
            let res= await orderService.getOrderList(requestbody);
            // res.list.map(val=>{
            //    let temp={
            //        "Mapped Source Orders":val.source_order_number,
            //        "Brand Name":val.brand_name,
            //        "CMS Internal ID":val.destination_order_id,
            //        "CMS External ID":val.cms_external_id,
            //        "Total Merchandized Cost":val.merchant_cost || 0,
            //        "shipping cost": val.shipping_cost || 0,
            //        "Grand Total": 0,
            //        "Order date":val.source_order_created_at,
            //        "Paid":val.paid?val.paid:false
            //
            //    };
            //     temp["Grand Total"] = temp["Total Merchandized Cost"] + temp["shipping cost"]
            //
            // });

            jsonInput.push(res['orders']);
        }
        convertToCsv(jsonInput)

    }
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }
    const handleRangeChage=(ranges)=>{
      if(ranges && ranges.length){
          let startDate=formatDate(ranges[0]);
          let endDate=formatDate(ranges[1]);
          startDate+='T00:00:00';
          endDate+='T23:59:59';
          setStartDate(startDate);
          setEndDate(endDate);
          setCurrentPage(1);
          console.log(startDate,endDate)
      } else {
          setStartDate(null);
          setEndDate(null);

      }
    }


    return (
        <>
            <div class="content_head_ui">
                <h2 className="heading">Orders </h2>
            </div>
            {renderOrderDetailsModal()}

            <Row className="order_inner-container" align={'middle'} justify={'space-between'} style={{width:'100w'}}>
                <Col>
                    <div className={"order-dropdown-outer"}>
                        <span>
                            <input className="custom-input1" placeholder={"Search for orders"} onChange={handleFilterChange} value={filter} />
                        </span>
                        {/* Brand Dropdown */}
                        {/*<span style={{ marginLeft: '16px' }}>*/}
                        {/*    <Select*/}
                        {/*      showSearch*/}
                        {/*      placeholder="Select Brand"*/}
                        {/*      optionFilterProp="children"*/}
                        {/*      className="custom-input2"*/}
                        {/*      onChange={handleBrandChange}*/}
                        {/*      style={{ width: 200 }}*/}
                        {/*    >*/}
                        {/*        {brandItems.map(item => (*/}
                        {/*            <Option  key={item.key} value={item.key}>{item.label}</Option>*/}
                        {/*        ))}*/}
                        {/*    </Select>*/}
                        {/*</span>*/}

                        {/* Payment Dropdown */}
                        <span style={{ marginLeft: '16px' }}>
                            <Select
                                showSearch
                                placeholder="Select Status"
                                optionFilterProp="children"
                                className="custom-input2"
                                onChange={handlePaymentChange}
                                style={{ width: 200 }}
                            >
                                {items.map(item => (
                                    <Option key={item.key} value={item.key}>{item.label}</Option>
                                ))}
                            </Select>
                        </span>
                        <span style={{ marginLeft: '16px' }}>
                            <DateRangePicker  className="custom-datepicker-order" format="MM/dd/yyyy" character=" – " onChange={handleRangeChage}/>
                        </span>
                  </div>
              </Col>
              <Col>
                  <Button className="custom-input1" onClick={getAllData}>Export to CSV</Button>
              </Col>
          </Row>

            <div className="orderTable">
                <Table
                    className='custom-table-wrap'
                    columns={column}
                    dataSource={tabledata}
                    loading={loadingTable}
                    page={currentPage}
                    scroll={{
                        x: 1300,
                    }}
                    pagination={
                        {
                            current: currentPage,
                            pageSize: 10,
                            showSizeChanger:false,
                            total: totalPages,
                            onChange: async (page) => {
                                console.log(page)
                                setCurrentPage(page);
                            }
                        }
                    }
                />
            </div>


            {/*{addShippingmodal &&*/}

            {/*<Modal*/}
            {/*    width={700}*/}
            {/*    title="Place Order"*/}
            {/*    style={{*/}
            {/*        textAlign: 'left'*/}
            {/*    }}*/}
            {/*    centered*/}
            {/*    open={addShippingmodal}*/}
            {/*    onCancel={() => setAddShippingModal(false)}*/}
            {/*    footer={null}*/}
            {/*>*/}
            {/*    {currentCheckoutList && currentCheckoutList.map((group) => {*/}
            {/*        return (*/}

            {/*            <>*/}
            {/*                {group.line_items.map((line_item) => {*/}
            {/*                    return (*/}
            {/*                        <div key={line_item.id}>*/}
            {/*                            <Row>Vendor: {line_item.vendor}</Row>*/}
            {/*                            <Row>Product Title: {line_item.title}</Row>*/}
            {/*                            <Row>product Prize: {line_item.price}</Row>*/}
            {/*                            <Row>Quantity: {line_item.quantity}</Row>*/}
            {/*                            <Row>*******************************************************************</Row>*/}
            {/*                        </div>*/}
            {/*                    );*/}
            {/*                })}*/}
            {/*            </>*/}
            {/*        )*/}
            {/*    })}*/}
            {/*</Modal>*/}
            {/*}*/}
        </>
    );
}

export default OrderDetails;
