import React, { useState, useEffect } from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import { useStoreService } from '../../services';

const PrivateWebhooksRoute = ({ children }) => {
    const { isWebhookAdded } = useStoreService();
    const [isWebHooksInstalled, setIsWebHooksInstalled] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const store_sync_id = sessionStorage.getItem('user');
    const store_details = JSON.parse(localStorage.getItem(store_sync_id));
    console.log(store_details, "STORE DETAILS")

    useEffect(() => {
        if (!store_details) return;
        const checkWebhooks = async () => {
            try {
                const result = await isWebhookAdded(store_details);
                setIsWebHooksInstalled(result.data);
            } catch (error) {
                console.error("Error checking webhook status", error);
            } finally {
                setLoading(false);
            }
        };
        checkWebhooks();
    }, [store_details?.store_sync_id, isWebhookAdded]);

    useEffect(() => {
        if (!loading && !isWebHooksInstalled) {
            navigate('/select_type', { replace: true });
        }
    }, [loading, isWebHooksInstalled, navigate]);

    if (loading) {
        return <div>Loading...</div>; // You can replace this with a spinner or loading component
    }

    return isWebHooksInstalled ? children :  <Navigate to="/select_type"/>;
};

export default PrivateWebhooksRoute;
